import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { isEligibleForFreeEvent } from 'libs/common/utils/CouponUtils';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DbCollections } from '../interfaces/Collections.enum';
import { CouponType } from '../interfaces/Coupon.enum';
import { Coupon, UsedCouponsUsedBy } from '../interfaces/Coupon.interface';
import { ServerApi } from '../interfaces/ServerApi.enum';
import { User } from '../interfaces/User.interface';
import { DatabaseService } from './database.service';
import { PopupDialogService } from './popup-dialog.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  private couponSubject = new BehaviorSubject<Coupon>(null);
  public coupon$: Observable<Coupon> = this.couponSubject.asObservable();
  public currentUser: User;
  private userSubscription?: Subscription;
  private freeEventCouponInitialize: boolean = true;
  private doesUserHaveVipCoupon = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private db: DatabaseService,
    private popupDialog: PopupDialogService,
    private firestore: AngularFirestore
  ) {
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      setTimeout(() => {
        this.doesUserHaveVipCoupon = this.currentUser && 'vipCoupons' in this.currentUser;
      }, 1500);
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private async updateUsedCoupon(
    couponData: Coupon,
    couponName: string,
    uuid: string,
    deleteCoupon: boolean = false
  ): Promise<void> {
    if (couponData && 'id' in couponData && couponData.id) {
      const res = await firstValueFrom(
        this.db.getById<UsedCouponsUsedBy>(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid)
      );

      if (res) {
        await this.db.update(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid, {
          used: [...res.used, firebase.firestore.Timestamp.fromDate(new Date())],
        });
      } else {
        await this.db.createWithId(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid, {
          userRef: this.firestore.doc(`${DbCollections.USERS}/${uuid}`).ref,
          used: [firebase.firestore.Timestamp.fromDate(new Date())],
        });
      }
    } else {
      const id = await this.db.createAndGetId(DbCollections.USED_COUPONS, couponData);
      await this.db.createWithId(`${DbCollections.USED_COUPONS}/${id}/usedBy`, uuid, {
        userRef: this.firestore.doc(`${DbCollections.USERS}/${uuid}`).ref,
        used: [firebase.firestore.Timestamp.fromDate(new Date())],
      });

      if (!deleteCoupon) {
        await this.db.update(DbCollections.COUPONS, couponName, { ...couponData, id: id });
      }
    }

    if (deleteCoupon) {
      await this.db.delete(DbCollections.COUPONS, couponName);
    }
  }

  private checkCoupon(coupon: string, isNewCustomer: boolean): Observable<Coupon> {
    return this.db.getById<Coupon>('coupons', coupon).pipe(
      switchMap(couponData => {
        if (
          !couponData ||
          couponData.expires < firebase.firestore.Timestamp.fromDate(new Date()) ||
          (couponData.isOnlyForNewCustomers && !isNewCustomer)
        ) {
          return of(null);
        } else {
          this.couponSubject.next({ name: coupon, ...couponData });
          return of({ name: coupon, ...couponData });
        }
      })
    );
  }

  private isNewCustomer(): boolean {
    return !(this.currentUser && 'activeEvents' in this.currentUser
      ? Number(this.currentUser.activeEvents) +
        ('totalEvents' in this.currentUser ? Number(this.currentUser.totalEvents) : 0)
      : 0);
  }

  private checkCouponFreeEvent(isVipCoupon: boolean): void {
    if (this.freeEventCouponInitialize) {
      this.freeEventCouponInitialize = false;
      const copyUser = Object.assign({}, this.currentUser);

      if (isVipCoupon && 'vipCoupons' in copyUser && copyUser.vipCoupons.leftEvents) {
        this.popupDialog.openMessageModalPopup({
          msg: 'admin_page.message_modal.message.vip_coupon_already_applied',
          success: false,
        });

        this.removeSavedCoupon();

        return;
      }

      this.coupon$.pipe(take(1)).subscribe(async coupon => {
        if ((coupon && coupon.discountType == CouponType.FREE_EVENT) || coupon.discountType == CouponType.VIP) {
          const updatedUserData = {
            ...this.currentUser,
            activeEvents: (
              (Number(this.currentUser.activeEvents) ? Number(this.currentUser.activeEvents) : 0) + coupon.freeEvents
            ).toString(),
          };

          if (isVipCoupon) {
            updatedUserData.vipCoupons = {
              expiration: coupon.expires,
              leftEvents: coupon.freeEvents,
            };
          }

          const res = await this.userService.update(updatedUserData);

          const deleteCoupon = res && coupon.onlyUseOnce;
          await this.updateUsedCoupon(coupon, coupon.name, this.currentUser.uuid, deleteCoupon);

          this.popupDialog.openMessageModalPopup({
            msg: res ? 'payment_method.popup.coupon.activated' : 'payment_method.popup.coupon.failed',
            success: res,
          });

          this.couponSubject.next(null);

          this.removeSavedCoupon();
        }
      });
    }
  }

  public async removeUsersVipCouponEvents(): Promise<boolean> {
    const updatedUserData = {
      activeEvents: (
        (Number(this.currentUser.activeEvents) ? Number(this.currentUser.activeEvents) : 0) -
        Number(this.currentUser.vipCoupons.leftEvents)
      ).toString(),
      vipCoupons: firebase.firestore.FieldValue.delete(),
    };

    return await this.db.update(DbCollections.USERS, this.currentUser.uuid, updatedUserData);
  }

  public async applyCoupon(
    coupon: string,
    isInputEntry: boolean = false,
    showPopup: boolean = false
  ): Promise<boolean> {
    const isNewCustomer = this.isNewCustomer();
    const couponData = await firstValueFrom(this.checkCoupon(coupon, isNewCustomer));

    if (couponData && isEligibleForFreeEvent(couponData.discountType) && !isInputEntry)
      this.checkCouponFreeEvent(couponData.discountType === CouponType.VIP);

    if (couponData && !isEligibleForFreeEvent(couponData.discountType) && !isInputEntry)
      this.popupDialog.openMessageModalPopup({
        msg: !!couponData ? 'payment_method.popup.coupon.activated' : 'payment_method.popup.coupon.failed',
        success: !!couponData,
      });

    if (!couponData) this.removeSavedCoupon();

    if (!couponData && showPopup) {
      this.popupDialog.openMessageModalPopup({
        msg: 'global.popup.coupon.doesnt_exist',
        success: false,
      });
    }

    return couponData && !isEligibleForFreeEvent(couponData.discountType);
  }

  public async checkIsCouponUsed(isPaymentSuccess: boolean): Promise<void> {
    if (isPaymentSuccess) {
      const couponName = this.checkSavedCoupons();
      const isNewCustomer = this.isNewCustomer();
      const couponData = await firstValueFrom(this.checkCoupon(couponName, isNewCustomer));
      const deleteCoupon = couponData && couponData.onlyUseOnce;
      if (couponName) await this.updateUsedCoupon(couponData, couponName, this.currentUser.uuid, deleteCoupon);
    }

    return;
  }

  public saveCoupon(couponCode: string): void {
    const d = new Date();
    d.setTime(d.getTime() + 10 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = 'couponCode' + '=' + couponCode + ';' + expires + ';path=/';
  }

  public checkSavedCoupons(): string | undefined {
    let b = document.cookie.split('; ');
    for (let e = b.length - 1; e >= 0; e--) {
      let c = b[e].split('=');
      if ('couponCode' === c[0]) return c[1];
    }
    return undefined;
  }

  public removeSavedCoupon(): void {
    document.cookie = 'couponCode' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.couponSubject.next(null);
  }

  public getUsedCouponsData(): Observable<Coupon[]> {
    return this.db.getAll<Coupon>(DbCollections.USED_COUPONS);
  }

  public getCoupons(): Observable<Coupon[]> {
    return this.db.getAll<Coupon>(DbCollections.COUPONS);
  }

  public async generateCoupons(coupons: string[], couponData: Coupon): Promise<void> {
    let couponsBatch: string[][] = [];
    for (const [index, value] of coupons.entries()) {
      let couponsBatchIndex = Math.floor(index / 500);

      if (!couponsBatch[couponsBatchIndex]) {
        couponsBatch[couponsBatchIndex] = [];
      }

      couponsBatch[couponsBatchIndex].push(value);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    for (const batches of couponsBatch) {
      let batch = this.firestore.firestore.batch();
      for (const coupon of batches) {
        let ref = this.firestore.firestore.collection('coupons').doc(coupon);
        batch.set(ref, couponData);
      }

      await batch.commit().catch(err => {
        console.error(err);
      });

      try {
        const serverRes = await firstValueFrom(
          this.http.post(`${environment.serverApi}${ServerApi.GENERATE_QR}`, batches, { headers })
        );
        if (serverRes) {
          this.popupDialog.openMessageModalPopup({
            msg: 'coupon_generator.popup.success.coupons_generation',
            success: true,
          });
        }
      } catch (err) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.coupons_generation',
          success: false,
        });
        console.error('Error sending coupons:', err);
      }
    }
  }
}
