import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UserPurchases } from 'src/app/definitions/Columns.definition';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DbCollections } from 'src/app/interfaces/Collections.enum';
import { ProductInfo, UserInfo } from 'src/app/interfaces/Pdf.interface';
import { ProductIds, ProductNames } from 'src/app/interfaces/Product.enum';
import { Purchase } from 'src/app/interfaces/Purchase.interface';
import { TableType } from 'src/app/interfaces/Table.enum';
import { PurchaseData } from 'src/app/interfaces/Table.interface';
import { User } from 'src/app/interfaces/User.interface';
import { FireDatePipe } from 'src/app/pipes/fire-date.pipe';
import { DatabaseService } from 'src/app/services/database.service';
import { PdfService } from 'src/app/services/pdf.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-purchases-page',
  templateUrl: './user-purchases-page.component.html',
  styleUrls: ['./user-purchases-page.component.scss'],
})
export class UserPurchasesPageComponent implements OnInit, OnDestroy {
  public readonly tableType = TableType;
  public readonly userPurchasesColumns = UserPurchases;
  public readonly ButtonType = ButtonType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public userPurchases: Purchase[] = [];
  public userPurchasesTableData: PurchaseData[] = [];
  public isDataLoaded: boolean = false;

  constructor(
    private userService: UserService,
    private db: DatabaseService,
    private pdfService: PdfService,
    private spinner: NgxSpinnerService,
    private fireDatePipe: FireDatePipe
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.db.getAll(`${DbCollections.CUSTOMERS}/${this.currentUser.uuid}/payments`).subscribe((res: any) => {
        this.userPurchasesTableData = res
          .map((purchase: Purchase) => {
            let createdDate: number;

            const createdTimestamp = (purchase.created as number).toString();
            if (createdTimestamp.length === 13) {
              createdDate = purchase.created;
            } else {
              createdDate = purchase.created * 1000;
            }

            return {
              id: purchase.id,
              status: purchase.status == 'succeeded',
              amountWithCurrency: `${(purchase.amount / 100).toFixed(2)} ${purchase.currency}`,
              createdDate: createdDate,
              last4: purchase.charges?.data[0]?.payment_method_details?.card?.last4 ?? '',
              type: purchase.charges?.data[0]?.payment_method_details?.type ?? '',
              brand: purchase.charges?.data[0]?.payment_method_details?.card?.brand ?? '',
            };
          })
          .sort((a: PurchaseData, b: PurchaseData) => b.createdDate - a.createdDate);

        this.userPurchases = res;

        this.isDataLoaded = true;
        this.spinner.hide();
      });
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public tableButtonTrigger(id: string) {
    const userInfo: UserInfo = {
      fullName: this.currentUser.firstName + ' ' + this.currentUser.lastName,
      phoneNumber: this.currentUser.phoneNumber,
      email: this.currentUser.email,
      companyName: this.currentUser.companyName,
      companyAddress: this.currentUser.companyAddress,
    };

    const product = this.userPurchases.find(obj => obj.id === id);
    const objWithDate = this.userPurchasesTableData.find(obj => obj.id === id);

    const formattedCreatedDate = this.fireDatePipe.transform(objWithDate.createdDate);

    let formattedDescription;

    switch (product.items[0].price.product) {
      case ProductIds.SMALL:
        formattedDescription = ProductNames.SMALL;
        break;
      case ProductIds.MEDIUM:
        formattedDescription = ProductNames.MEDIUM;
        break;
      case ProductIds.BIG:
        formattedDescription = ProductNames.BIG;
        break;
      default:
        formattedDescription = '';
    }

    const productInfo: ProductInfo = {
      name: formattedDescription,
      quantity: 1,
      price: product.amount / 100,
      currency: product.currency.toUpperCase(),
    };

    const invoiceId = !!product.invoiceId ? product.invoiceId : id.startsWith('pi_') ? id.substring(3) : id;

    this.pdfService.generateInvoicePdf(invoiceId, formattedCreatedDate, userInfo, productInfo);
  }
}
