import { ChangeDetectorRef, Component } from '@angular/core';
import firebase from 'firebase/compat/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DbCollections } from 'src/app/interfaces/Collections.enum';
import { HeaderData } from 'src/app/interfaces/Header.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { Role } from 'src/app/interfaces/Role.enum';
import { User } from 'src/app/interfaces/User.interface';
import { CouponService } from 'src/app/services/coupon.service';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent {
  public readonly PageLayoutType = PageLayoutType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public readonly role = Role;

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private coupon: CouponService,
    private db: DatabaseService
  ) {}

  private async checkCoupon(user: User) {
    const couponCode = this.coupon.checkSavedCoupons();
    if (couponCode || !!user.couponCode) {
      await this.coupon.applyCoupon(!!user.couponCode ? user.couponCode : couponCode, false, true);
      if (!!user.couponCode) {
        await this.db.update(DbCollections.USERS, user.uuid, {
          couponCode: firebase.firestore.FieldValue.delete(),
        });
      }
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.spinner.hide();
      this.cdr.detectChanges();

      if (user && user.role === Role.USER) {
        this.checkCoupon(user);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public getUserHeaderData(): HeaderData {
    return {
      userRole: this.currentUser.role,
      userFirstName: this.currentUser.firstName,
      userLastName: this.currentUser.lastName,
      userProfileImg: this.currentUser.profileImg,
    };
  }
}
