import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { OAuthProvider, getAuth, sendPasswordResetEmail, signInWithPopup } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs';
import { FormCredentials } from '../interfaces/Form.interface';
import { LoginCredentials } from '../interfaces/LoginCredentials.interface';
import { NavigationPaths } from '../interfaces/NavigationPaths.enum';
import { PopupType } from '../interfaces/Popup.enum';
import { RegistrationCredentials } from '../interfaces/Registration.interface';
import { Role } from '../interfaces/Role.enum';
import { PublicUserRoutes } from '../interfaces/Routes.enum';
import { User } from '../interfaces/User.interface';
import { CouponService } from './coupon.service';
import { DatabaseService } from './database.service';
import { PopupDialogService } from './popup-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public googleProvider = new OAuthProvider('google.com');
  public microsoftProvider = new OAuthProvider('microsoft.com');
  public appleProvider = new OAuthProvider('apple.com');
  public readonly PopupType = PopupType;

  constructor(
    private auth: AngularFireAuth,
    private db: DatabaseService,
    private router: Router,
    private popupDialog: PopupDialogService,
    private spinner: NgxSpinnerService,
    private injector: Injector
  ) {}

  private routeUser(path: string): void {
    const packageNumber = localStorage.getItem('packageNumber');
    if (packageNumber && !this.router.url.includes(NavigationPaths.USER_PACKAGES)) {
      this.router.navigate([NavigationPaths.USER_PACKAGES]);
      return;
    }
    this.router.navigate([path]);
  }

  public logout = async () => {
    try {
      await this.auth.signOut();

      this.router.navigate([PublicUserRoutes.LOGIN]);
    } catch (err) {
      console.error(err);
    }
  };

  public regEmail = async (userInfo: RegistrationCredentials): Promise<boolean> => {
    this.spinner.show();
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(userInfo.email, userInfo.password);

      const isEmailSent = await userCredential.user
        ?.sendEmailVerification()
        .then(() => {
          return true;
        })
        .catch(err => {
          console.error(err);
          return false;
        });

      if (isEmailSent) {
        const userData: User = {
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phoneNumber: userInfo.phoneNumber ? userInfo.phoneNumber : '',
          profileImg: '',
          isEmailVerified: true,
          role: Role.USER,
          uuid: userCredential.user.uid,
          registrationDate: firebase.firestore.Timestamp.now(),
        };

        if (userInfo.couponCode) {
          userData.couponCode = userInfo.couponCode;
          const coupon = this.injector.get(CouponService);
          coupon.removeSavedCoupon();
        }

        const uid = userCredential.user?.uid;

        const dbRes = await this.db.createWithId('/users', uid, userData);

        if (dbRes) {
          this.spinner.hide();
          this.popupDialog.openPopup({
            type: PopupType.DEFAULT,
            title: 'registration_page.popup.email_verification_sent.title',
            description: 'registration_page.popup.email_verification_sent.description',
            icon: 'bi_send-check-icon.svg',
            button: 'registration_page.popup.email_verification_sent.button',
            buttonFunction: () => {
              this.router.navigate([PublicUserRoutes.LOGIN]);
            },
          });

          await this.auth.signOut();
          return true;
        }
      }
    } catch (err) {
      this.spinner.hide();
      if (typeof err == 'object' && 'code' in err) {
        switch (err.code) {
          case 'auth/email-already-in-use':
            this.popupDialog.openPopup({
              type: PopupType.DEFAULT,
              title: 'registration_page.popup.email_already_in_use.title',
              description: 'registration_page.popup.email_already_in_use.description',
              icon: 'bi_send-check-icon.svg',
              button: 'registration_page.popup.email_already_in_use.button',
              buttonFunction: () => {
                this.router.navigate([PublicUserRoutes.PASSWORD_RESET]);
              },
            });
            break;
        }
      }
      console.error(err);
    }

    return false;
  };

  public async login(loginInfo: LoginCredentials): Promise<void> {
    this.spinner.show();
    try {
      const userCredential = await this.auth
        .signInWithEmailAndPassword(loginInfo.email, loginInfo.password)
        .catch(err => {
          this.spinner.hide();
          switch (err.code) {
            case 'auth/user-not-found':
              this.popupDialog.openPopup({
                type: PopupType.DEFAULT,
                title: 'login_page.popup.user_not_found.title',
                description: 'login_page.popup.user_not_found.description',
                icon: 'bi_send-check-icon.svg',
                button: 'login_page.popup.user_not_found.button',
                buttonFunction: () => {
                  this.router.navigate([PublicUserRoutes.REGISTRATION]);
                },
              });
              break;
            case 'auth/wrong-password':
              this.popupDialog.openPopup({
                type: PopupType.DEFAULT,
                title: 'login_page.popup.wrong_password.title',
                description: 'login_page.popup.wrong_password.description',
                icon: 'bi_send-check-icon.svg',
                button: 'password_reset_page.button.password_reset.text',
                buttonFunction: () => {
                  this.router.navigate([PublicUserRoutes.PASSWORD_RESET]);
                },
              });
              break;
            case 'auth/invalid-email':
              this.popupDialog.openPopup({
                type: PopupType.DEFAULT,
                title: 'login_page.popup.invalid_email.title',
                description: 'login_page.popup.invalid_email.description',
                icon: 'bi_send-check-icon.svg',
              });
              break;
          }
        });

      if (userCredential) {
        // TODO: Update this after email verification logic is done
        // if (!userCredential.user?.emailVerified) {
        //   this.popupDialog.openPopup({
        //     type: PopupType.DEFAULT,
        //     title: 'login_page.popup.email_verification_false.title',
        //     description: 'login_page.popup.email_verification_false.description',
        //     icon: 'bi_send-check-icon.svg',
        //   });
        // } else {
        //   this.db.getById('/users', userCredential.user.uid).subscribe(async dbRes => {
        //     if (typeof dbRes == 'object' && 'role' in dbRes) {
        //       if (dbRes.role === Role.ADMIN) {
        //         this.router.navigate(['/admin']);
        //       } else {
        //         this.router.navigate(['/user']);
        //       }
        //     }
        //   });
        // }
        this.db
          .getById('/users', userCredential.user.uid)
          .pipe(take(1))
          .subscribe(async dbRes => {
            if (typeof dbRes == 'object' && 'role' in dbRes) {
              this.spinner.hide();
              if (dbRes.role === Role.ADMIN) {
                this.router.navigate(['/admin']);
              } else {
                const packageNumber = localStorage.getItem('packageNumber');
                if (packageNumber) {
                  this.router.navigate([NavigationPaths.USER_PACKAGES, packageNumber]);
                } else {
                  this.router.navigate(['/user']);
                }
              }
            }
          });
      }
    } catch (err) {
      this.spinner.hide();
      console.error(err);
    }
  }

  public async anonymousLogin(formCredentials: FormCredentials): Promise<boolean> {
    this.spinner.show();
    try {
      const userCredential = await this.auth.signInAnonymously();

      if (userCredential.user) {
        const newUser: User = {
          email: formCredentials['email'] || '',
          firstName: formCredentials['firstName'],
          lastName: formCredentials['lastName'],
          phoneNumber: formCredentials['phoneNumber'] || '',
          profileImg: '',
          isEmailVerified: true,
          role: Role.CLIENT,
          uuid: userCredential.user.uid,
          isAnonymous: true,
          registrationDate: firebase.firestore.Timestamp.now(),
        };

        const res = await this.db.createWithId('/users', userCredential.user.uid, newUser);

        if (res) {
          this.spinner.hide();
          this.router.navigate(['/client/event']);
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      this.spinner.hide();
      console.error(error);
    }

    return false;
  }

  public async authWithProvider(provider: OAuthProvider): Promise<void> {
    try {
      const result = await signInWithPopup(getAuth(), provider);

      if (result.user) {
        this.db
          .getById('/users', result.user.uid)
          .pipe(take(1))
          .subscribe(async dbRes => {
            if (!dbRes) {
              const newUser: User = {
                email: result.user.email || '', // default to empty string if email is null
                firstName: result.user.displayName ? result.user.displayName.split(' ')[0] : '', // split the displayName to get the firstName
                lastName: result.user.displayName ? result.user.displayName.split(' ').slice(1).join(' ') : '', // split the displayName to get the lastName
                phoneNumber: result.user.phoneNumber || '', // default to empty string if phoneNumber is null
                profileImg: '',
                isEmailVerified: true,
                role: Role.USER,
                uuid: result.user.uid,
                isProfileCompleted: !!result.user?.displayName,
                registrationDate: firebase.firestore.Timestamp.now(),
              };

              await this.db.createWithId('/users', result.user.uid, newUser);
              this.router.navigate(['/user']);
              return;
            }

            if (typeof dbRes == 'object' && 'role' in dbRes) {
              if (dbRes.role === Role.ADMIN) {
                this.router.navigate(['/admin']);
              } else {
                const packageNumber = localStorage.getItem('packageNumber');
                if (packageNumber) {
                  this.router.navigate([NavigationPaths.USER_PACKAGES, packageNumber]);
                } else {
                  this.router.navigate(['/user']);
                }
              }
            }
          });
      }
    } catch (err) {
      console.error(err);
    }
  }

  public passwordReset(email: string): void {
    sendPasswordResetEmail(getAuth(), email)
      .then(res => {
        this.popupDialog.openPopup({
          type: PopupType.DEFAULT,
          title: 'password_reset_page.popup.successful_reset.title',
          description: 'password_reset_page.popup.successful_reset.description',
          icon: 'bi_send-check-icon.svg',
        });
      })
      .catch(err => {
        switch (err.code) {
          case 'auth/user-not-found':
            this.popupDialog.openPopup({
              type: PopupType.DEFAULT,
              title: 'password_reset_page.popup.failed_reset.user_not_found.title',
              description: 'password_reset_page.popup.failed_reset.user_not_found.description',
              icon: 'bi_send-check-icon.svg',
              button: 'password_reset_page.popup.failed_reset.user_not_found.button',
              buttonFunction: () => {
                this.router.navigate([PublicUserRoutes.REGISTRATION]);
              },
            });
            break;
          case 'auth/invalid-email':
            this.popupDialog.openPopup({
              type: PopupType.DEFAULT,
              title: 'password_reset_page.popup.failed_reset.invalid_email.title',
              description: 'password_reset_page.popup.failed_reset.invalid_email.description',
              icon: 'bi_send-check-icon.svg',
            });
            break;
          case 'auth/too-many-requests':
            this.popupDialog.openPopup({
              type: PopupType.DEFAULT,
              title: 'password_reset_page.popup.failed_reset.too_many_requests.title',
              description: 'password_reset_page.popup.failed_reset.too_many_requests.description',
              icon: 'bi_send-check-icon.svg',
            });
            break;
        }
      });
  }

  public async authWithGoogle(): Promise<void> {
    await this.authWithProvider(this.googleProvider);
  }

  public async authWithMicrosoft(): Promise<void> {
    await this.authWithProvider(this.microsoftProvider);
  }

  public async authWithApple(): Promise<void> {
    await this.authWithProvider(this.appleProvider);
  }
}
