import { Injectable } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { customAlphabet } from 'nanoid';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { DbCollections } from '../interfaces/Collections.enum';
import { PaymentErrorCode } from '../interfaces/ErrorCode.enum';
import { PaymentStatus } from '../interfaces/Payment.enum';
import { Payment } from '../interfaces/Payment.interface';
import { ProductIds } from '../interfaces/Product.enum';
import { Product } from '../interfaces/Product.interface';
import { UserRoutes } from '../interfaces/Routes.enum';
import { User } from '../interfaces/User.interface';
import { DatabaseService } from './database.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private _isSuccessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSuccess$: Observable<boolean> = this._isSuccessSubject.asObservable();
  private isHomePage: boolean;

  constructor(
    private userService: UserService,
    private db: DatabaseService,
    private firestore: AngularFirestore,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  public fetchPayments(user: User, home?: boolean): void {
    this.isHomePage = home ?? false;

    if (this.isHomePage && sessionStorage.getItem('paymentsFetched')) {
      return; // Exit if payments have already been fetched for this session and it's home page
    }

    this.spinner.show();

    const paymentRef = this.firestore.collection(`${DbCollections.CUSTOMERS}/${user.uuid}/payments`);

    paymentRef.get().subscribe(
      querySnapshot => {
        const typedQuerySnapshot = querySnapshot as QuerySnapshot<Payment>;

        let successPayments: Payment[] = [];

        const userUsedPayments: string[] = 'usedPayments' in user ? user.usedPayments : [];

        typedQuerySnapshot.docs.forEach(payment => {
          if (payment.data().status === 'succeeded' && !userUsedPayments.includes(payment.data().id))
            successPayments.push(payment.data());
        });

        if (this.isHomePage) {
          sessionStorage.setItem('paymentsFetched', 'true');
        }

        if (!typedQuerySnapshot.docs.length && !this.isHomePage) {
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'errorPayments');
        }

        if (!successPayments.length && !this.isHomePage) {
          this.handlePaymentRedirect(PaymentErrorCode.CODE_1);
          return;
        }

        this.processPayments(successPayments, user, this.isHomePage);
      },
      err => {
        if (!home) {
          console.error('Error fetching payments:', err);
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'errorPayments');
          this.handlePaymentRedirect(PaymentErrorCode.CODE_3);
        }
      }
    );
  }

  private processPayments(successPayments: Payment[], user: User, home?: boolean): void {
    successPayments.forEach(async (payment, index) => {
      const items = payment.items;
      const product = payment.items[0].price.product;
      // TODO: take a look at this logic
      // Update statistics for succededPayments based on status
      if (payment.status === PaymentStatus.SUCCEEDED) {
        const country = payment.charges.data[0].billing_details.address.country;
        this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'succeededPayments');
        this.db.incrementFieldValue(DbCollections.STATISTICS, 'country', country);
      }

      // Update statistics for each type of package/product sold
      switch (product) {
        case ProductIds.SMALL:
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'packageSoldSmall');
          break;
        case ProductIds.MEDIUM:
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'packageSoldMedium');
          break;
        case ProductIds.BIG:
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'packageSoldBig');
          break;
      }

      await this.createInvoiceDoc(user, payment.id, payment.created, 'invoiceId' in payment);

      if (Array.isArray(items)) {
        const firstItem = items[0];
        const prodId = firstItem.price.product;

        this.fetchProductAndUpdateUser(user, prodId, payment.id, home);
      }
    });
  }

  private fetchProductAndUpdateUser(user: User, prodId: string, paymentDocId: string, home?: boolean): void {
    this.db.getById(DbCollections.PRODUCTS, prodId).subscribe(res => {
      const events = (res as Product).metadata.events;

      const updatedUserData = {
        ...user,
        usedPayments: [...(user.usedPayments || []), paymentDocId],
        activeEvents: ((Number(user.activeEvents) ? Number(user.activeEvents) : 0) + Number(events)).toString(),
      };

      this.updateUserService(updatedUserData, home);
    });
  }

  private updateUserService(userData: User, home?: boolean): void {
    this.userService
      .update(userData)
      .then(() => {
        this._isSuccessSubject.next(true);
      })
      .catch(err => {
        if (!home) {
          console.error(err);
          this.db.incrementFieldValue(DbCollections.STATISTICS, 'admin', 'errorPayments');
          this.handlePaymentRedirect(PaymentErrorCode.CODE_2);
        }
      })
      .finally(() => {
        this.spinner.hide();
        this._isSuccessSubject.next(true);
      });
  }

  private handlePaymentRedirect(code: string): void {
    this.spinner.hide();
    this.router.navigate([UserRoutes.USER, UserRoutes.PAYMENT, UserRoutes.CANCELED], { queryParams: { code } });
  }

  private createInvoiceId(paymentCreated: number): string {
    const date = new Date(paymentCreated * 1000);

    const year = date.getFullYear().toString().substring(2); // Extract last two digits of the year
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day

    const formattedDate = year + month + day;

    const id = customAlphabet('0123456789', 4)();

    const invoiceId = formattedDate + id;

    return invoiceId;
  }

  public async createInvoiceDoc(
    user: User,
    paymentId: string,
    paymentCreated: number,
    alreadyHaveInvoice: boolean
  ): Promise<void> {
    if (!alreadyHaveInvoice) {
      let invoiceId = '';
      let docExists = true;
      while (docExists) {
        invoiceId = this.createInvoiceId(paymentCreated);
        docExists = await new Promise((resolve, reject) => {
          this.firestore
            .collection('invoicesId')
            .doc(invoiceId)
            .get()
            .pipe(first())
            .subscribe({
              next: data => {
                resolve(data.exists);
              },
              error: err => {
                reject(err);
              },
            });
        });
      }

      const res = await this.db.createWithId('invoicesId', invoiceId, { id: invoiceId });
      if (res) {
        const resPayment = await this.db.update(`/customers/${user.uuid}/payments`, paymentId, {
          invoiceId: invoiceId,
        });
        if (resPayment) {
          console.log('Successfully created invoice');
          return;
        }
      }

      console.error('Invoice creation failed');
    } else {
      console.log('Invoice already exists');
    }
  }
}
