import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/interfaces/Event.interface';
import { EventInfoBlock } from 'src/app/interfaces/EventInfoBlock.interface';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-user-event-info-page',
  templateUrl: './user-event-info-page.component.html',
  styleUrls: ['./user-event-info-page.component.scss'],
})
export class UserEventInfoPageComponent implements OnDestroy {
  public eventRefId: string;
  public saleHoursLeft: number;
  public saleMinutesLeft: number;
  private eventSubscription?: Subscription;
  public eventGeneralInfoBlock: EventInfoBlock[] = [];
  public eventSpecificInfoBlock: EventInfoBlock[] = [];

  constructor(private route: ActivatedRoute, private eventsService: EventsService) {
    this.eventRefId = this.route.parent.snapshot.paramMap.get('eventRefId');
    this.eventSubscription = this.eventsService
      .getEventAsObservable(this.eventRefId)
      .subscribe((event: Event | boolean) => {
        if (typeof event !== 'boolean') {
          this.calculateSaleEnd(event);

          this.eventGeneralInfoBlock = [
            this.generateEventInfoBlock('event_page.info.event_code', this.eventRefId),
            this.generateEventInfoBlock('event_page.info.event_date', event?.eventDate.toMillis() || '', 'mediumDate'),
            this.generateEventInfoBlock(
              'event_page.info.price_per_picture',
              event.price && `${event.price} ${event.currency}`
            ),
          ];

          this.eventSpecificInfoBlock = [
            this.generateEventInfoBlock(
              'event_page.info.time_left',
              this.isSaleActive(event)
                ? 'event_page.info.time_remaining'
                : event?.saleEnd
                ? 'event_page.info.sale_ended'
                : 'event_page.info.no_sale_end'
            ),
          ];

          if (event?.saleEnd) {
            this.eventSpecificInfoBlock.push(
              this.generateEventInfoBlock('event_page.info.event_sale_ends', event.saleEnd.toMillis())
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  private isSaleActive(event: Event): boolean {
    return event.saleEnd >= firebase.firestore.Timestamp.fromDate(new Date());
  }

  private generateEventInfoBlock(title: string, value: string | number, dateFormat?: string): EventInfoBlock | null {
    if (value !== null && value !== undefined) {
      return {
        dataTitle: title,
        data: value,
        isDate: typeof value === 'string' ? false : true,
        dateFormat: dateFormat ? dateFormat : 'medium',
      };
    }
    return null;
  }

  private calculateSaleEnd(event: Event): void {
    const saleEndDate = event.saleEnd?.toDate().getTime();
    const currentTime = new Date().getTime();

    const timeDifference = saleEndDate - currentTime;

    if (timeDifference > 0) {
      this.saleHoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.saleMinutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    }
  }
}
