<div class="py-17.5 px-10 relative flex items-center min-h-screen max-lg:px-10 max-sm:py-8 max-sm:px-6">
  <div
    class="px-6 py-8 mx-auto max-w-2xl z-10 rounded-2xl w-108 shadow-container bg-white top-0 lg:ml-[30%] lg:translate-x-[-60%] sm:mx-0"
  >
    <div class="flex items-center justify-between gap-2">
      <div class="w-32" aria-label="Logo" inlineSVG="./assets/images/photo-seller-logo.svg"></div>

      <app-language></app-language>
    </div>

    <app-title [type]="titleType.BIG" [text]="page.title" customClasses="mt-8"></app-title>

    <app-description [type]="descriptionType.MEDIUM" customClasses="mt-4" [text]="page.description"></app-description>

    <div
      *ngIf="page.type === layoutType.LOGIN || page.type === layoutType.REGISTRATION"
      class="flex flex-col gap-2 mt-6"
    >
      <app-button
        (click)="authWithApple()"
        [buttonInfo]="{
          text:
            page.type === layoutType.LOGIN
              ? 'login_page.button.apple_login.text'
              : 'registration_page.button.apple_registration.text',
          type: buttonType.WHITE_RECTANGLE,
          icon: 'apple-icon.svg'
        }"
      ></app-button>

      <app-button
        (click)="authWithGoogle()"
        [buttonInfo]="{
          text:
            page.type === layoutType.LOGIN
              ? 'login_page.button.google_login.text'
              : 'registration_page.button.google_registration.text',
          type: buttonType.WHITE_RECTANGLE,
          icon: 'google-icon.svg'
        }"
      ></app-button>

      <app-button
        (click)="authWithMicrosoft()"
        [buttonInfo]="{
          text:
            page.type === layoutType.LOGIN
              ? 'login_page.button.microsoft_login.text'
              : 'registration_page.button.microsoft_registration.text',
          type: buttonType.WHITE_RECTANGLE,
          icon: 'microsoft-icon.svg'
        }"
      ></app-button>
    </div>

    <div
      *ngIf="page.type === layoutType.LOGIN || page.type === layoutType.REGISTRATION"
      class="flex items-center gap-2 mx-auto my-5 w-14"
    >
      <div class="w-full h-px bg-neutral-400"></div>
      <p class="inline-block font-normal text-neutral-400 font-montserrat text-medium-sm">
        {{ 'global.separator.text' | translate }}
      </p>
      <div class="w-full h-px bg-neutral-400"></div>
    </div>

    <app-form
      *ngIf="page.form"
      [formInfo]="page.form"
      [filledInputs]="filledInputs"
      (formDataEmitter)="emitFromForm($event)"
    ></app-form>

    <div
      *ngIf="
        page.type === layoutType.LOGIN ||
        page.type === layoutType.REGISTRATION ||
        page.type === layoutType.PASSWORD_RESET
      "
      class="flex flex-wrap justify-center gap-1 mt-4"
    >
      <p
        *ngIf="page.type === layoutType.LOGIN || page.type === layoutType.REGISTRATION"
        class="font-normal text-slate-400 font-montserrat text-medium-sm"
      >
        {{
          (page.type === layoutType.LOGIN
            ? 'login_page.paragraph.dont_have_account'
            : 'registration_page.paragraph.have_account_already'
          ) | translate
        }}
      </p>
      <a
        [routerLink]="'/' + getRouterLink(page.type)"
        class="font-medium text-sky-700 font-montserrat text-medium-sm"
        href=""
      >
        {{ getLinkText(page.type) | translate }}
      </a>
    </div>
  </div>

  <div
    class="bg-registration-login top-0 right-0 w-70% h-full bg-no-repeat bg-cover bg-center absolute z-0 max-sm:w-full"
  ></div>
</div>
