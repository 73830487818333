import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat/app';

@Pipe({
  name: 'fireDate',
})
@Injectable({
  providedIn: 'root',
})
export class FireDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: firebase.firestore.Timestamp | Date | string | number, format?: string): string | null {
    let jsValue;

    if (value instanceof firebase.firestore.Timestamp) {
      jsValue = value.toMillis();
    } else {
      jsValue = value;
    }

    return this.datePipe.transform(jsValue, format);
  }
}
