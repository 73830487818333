import { Injectable } from '@angular/core';
import { REGEXES } from '../definitions/Regex.definitions';
import { ErrorMessagesType } from '../interfaces/ErrorMessages.enum';
import { ErrorMessages } from '../interfaces/ErrorMessages.interface';
import { FormCredentials, Input } from '../interfaces/Form.interface';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private errMsgs: ErrorMessages;

  constructor() {}

  private testRegex(type: ErrorMessagesType, value: string): boolean {
    return REGEXES[type].test(value);
  }

  private addErrorMsg(type: ErrorMessagesType, msg: string = ''): ErrorMessages {
    this.errMsgs = {
      ...this.errMsgs,
      [type]: msg,
    };

    return this.errMsgs;
  }

  public validateInput(
    type: ErrorMessagesType,
    value: string,
    required: boolean,
    valueToCompare?: string
  ): ErrorMessages {
    // Checks if it's required
    if (required && !value) {
      return this.addErrorMsg(type, 'global.form.validation_message.required');
    }

    // If there is err except required and invalid values
    switch (type) {
      case ErrorMessagesType.firstName:
        if (value.length < 2) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.first_name_length');
        }
        break;
      case ErrorMessagesType.lastName:
        if (value.length < 2) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.last_name_length');
        }
        break;
      case ErrorMessagesType.password:
        if (value.length < 8) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.password.length');
        }
        break;
      case ErrorMessagesType.eventRefId:
        // Event Ref ID length Check
        if (value.length < 6) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.event_ref_id_length.below');
        } else if (value.length > 6) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.event_ref_id_length.over');
        }
        break;
      case ErrorMessagesType.passwordConfirmation:
        if (value.length < 8) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.password.length');
        } else if (valueToCompare && value !== valueToCompare) {
          return this.addErrorMsg(type, 'global.form.validation_message.invalid.password_confirmation.mismathc');
        }
        break;
    }

    // Checks values with regex
    if (value !== '' && !this.testRegex(type, value)) {
      let outputString = '';
      if (type === ErrorMessagesType.password || type === ErrorMessagesType.passwordConfirmation) {
        outputString = 'password.character';
      } else {
        for (let i = 0; i < type.length; i++) {
          const currentChar = type[i];
          if (currentChar === currentChar.toUpperCase() && i > 0) {
            outputString += '_' + currentChar.toLowerCase();
          } else {
            outputString += currentChar.toLowerCase();
          }
        }
      }
      return this.addErrorMsg(type, `global.form.validation_message.invalid.${outputString}`);
    }

    return this.addErrorMsg(type);
  }

  public areRequiredFieldsFulfilled(formCredentials: FormCredentials, inputs: Input[]): boolean {
    for (let i = 0; i < inputs.length; i++) {
      if (!formCredentials[inputs[i].id] && inputs[i].required) {
        return false;
      }
    }

    return true;
  }

  public hasErrorMsgs(inputs: Input[]): boolean {
    for (let i = 0; i < inputs.length; i++) {
      if (this.errMsgs && inputs[i].id in this.errMsgs && this.errMsgs[`${inputs[i].id}` as ErrorMessagesType] !== '') {
        return false;
      }
    }
    return true;
  }
}
