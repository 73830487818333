export enum FormType {
  REGISTRATION = 'registration',
  LOGIN = 'login',
  PASSWORD_RESET = 'passwordReset',
  EDIT_USER_POPUP = 'editUserPopup',
  EDIT_PROFILE = 'profileEdit',
  EDIT_PROFILE_POPUP = 'profileEditPopup',
  CLIENT_LOGIN = 'clientLogin',
  CLIENT_DATA = 'clientData',
  CLIENT_EVENT_INPUT = 'clientEventInput',
  ADD_EVENT = 'addEvent',
  EDIT_EVENT = 'editEvent',
  COUPON_APPLY = 'couponApply',
}

export enum FormInputType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
  OPTIONS = 'options',
  SWITCH = 'switch',
  IMAGE_UPLOAD = 'imageUpload',
  DATE_PICKER = 'datePicker',
}

export enum FormFieldsId {
  IS_EMAIL_VERIFIED = 'isEmailVerified',
  IS_BLOCKED = 'isBlocked',
}

export enum FormInputId {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation',
  ACTIVE_EVENTS = 'activeEvents',
  ROLE = 'role',
  NAME = 'name',
  PRICE = 'price',
  CURRENCY = 'currency',
  EVENT_DATE = 'eventDate',
  IMAGE = 'image',
  TABLE_NUMBER = 'tableNumber',
  COMPANY_NAME = 'companyName',
  COMPANY_ADDRESS = 'companyAddress',
  EVENT_REF_ID = 'eventRefId',
  COUPON_CODE = 'couponCode',
}

export enum FormLabelColor {
  WHITE = 'white',
}
