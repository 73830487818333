<form
  #form="ngForm"
  class="flex flex-col"
  (ngSubmit)="
    formInfo.type === FormType.LOGIN || formInfo.type === FormType.REGISTRATION ? captchaRef.execute() : submitForm()
  "
>
  <div
    class="flex flex-wrap gap-x-8"
    [class]="
      (formInfo.customInputClasses ? formInfo.customInputClasses : '') +
      ' ' +
      (formInfo.type === FormType.PASSWORD_RESET ? 'mt-6' : '')
    "
  >
    <div
      [class]="
        element.type === FormInputType.EMAIL ||
        element.type === FormInputType.PASSWORD ||
        element.type === FormInputType.TEXT ||
        element.type === FormInputType.OPTIONS ||
        element.type === FormInputType.NUMBER ||
        element.type === FormInputType.IMAGE_UPLOAD ||
        element.type === FormInputType.DATE_PICKER
          ? 'w-full'
          : ''
      "
      *ngFor="let element of formInfo.elements"
      class="relative mt-4 font-medium text-zinc-900 first:mt-0"
    >
      <label class="font-medium" [class]="getLabelColor()" [htmlFor]="element.id"
        >{{ element.label | translate }}{{ element.required ? '*' : '' }}</label
      >
      <div *ngIf="element.inputId" class="relative w-full mt-1">
        <!-- Text Inputs -->
        <input
          *ngIf="element.type !== FormInputType.NUMBER"
          protectDisabled
          class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
          [class]="element.disabled ? 'cursor-not-allowed' : ''"
          [ngClass]="formInfo.containsErrorMsg && hasError(element.inputId) ? 'border-red-800' : ''"
          [type]="
            (element.id === FormInputId.PASSWORD || element.id === FormInputId.PASSWORD_CONFIRMATION) &&
            isPasswordVisible
              ? 'text'
              : element.type
          "
          [id]="element.id"
          [name]="element.id"
          [required]="element.required"
          [placeholder]="element.placeholder | translate"
          [(ngModel)]="formCredentials[element.inputId]"
          (ngModelChange)="validationFunc(element.inputId, $event, element.required)"
          [disabled]="element.disabled ? element.disabled : false"
          min="0"
        />

        <!-- Number Inputs -->
        <input
          *ngIf="element.type === FormInputType.NUMBER"
          protectDisabled
          blockNegativeNumbers
          class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
          [class]="element.disabled ? 'cursor-not-allowed' : ''"
          [ngClass]="formInfo.containsErrorMsg && hasError(element.inputId) ? 'border-red-800' : ''"
          [type]="
            (element.id === FormInputId.PASSWORD || element.id === FormInputId.PASSWORD_CONFIRMATION) &&
            isPasswordVisible
              ? 'text'
              : element.type
          "
          [id]="element.id"
          [name]="element.id"
          [required]="element.required"
          [placeholder]="element.placeholder | translate"
          [(ngModel)]="formCredentials[element.inputId]"
          (ngModelChange)="validationFunc(element.inputId, $event, element.required)"
          [disabled]="element.disabled ? element.disabled : false"
          [attr.autocapitalize]="element.disableAutoCapitalize ? 'off' : 'on'"
          min="0"
        />

        <p *ngIf="element.disclaimer" class="mt-2 text-xs text-zinc-400">
          {{ element.disclaimer | translate }}
        </p>

        <!-- Button for Show/Hide Password -->
        <button *ngIf="element.id === FormInputId.PASSWORD" type="button" (click)="togglePasswordShow()">
          <app-icon
            class="z-10 absolute top-2.5 right-2.5"
            [icon]="getPasswordIcon()"
            color="slate-400"
            width="w-5"
            alt="Show/Hide Password"
          ></app-icon>
        </button>
      </div>

      <div class="mt-1" *ngIf="element.type === FormInputType.DATE_PICKER">
        <app-date-picker [date]="getDate()" (emitSelectedDate)="setDate($event)"></app-date-picker>
      </div>

      <div
        fileDragDrop
        [isUploadInProgress]="blockFileUpload"
        (filesChangeEmiter)="setImage($event)"
        (filesHover)="onFileHover($event)"
        *ngIf="element.type === FormInputType.IMAGE_UPLOAD"
        class="box-border relative flex justify-center py-6 border-2 border-dashed border-slate-300"
      >
        <p
          *ngIf="isFileHovered"
          class="absolute z-40 -translate-x-1/2 -translate-y-1/2 pointer-events-none top-1/2 left-1/2 text-sky-900"
        >
          {{ 'global.text.drop_image' | translate }}
        </p>
        <div
          *ngIf="blockFileUpload || isFileHovered"
          class="absolute top-0 z-30 w-full h-full bg-blue-100 pointer-events-none"
        ></div>
        <div
          [class]="blockFileUpload ? '' : 'hidden'"
          class="absolute z-40 w-9/12 overflow-hidden -translate-x-1/2 -translate-y-1/2 bg-transparent border rounded-full h-7 top-1/2 left-1/2 border-sky-700"
        >
          <div class="h-full duration-300 ease-out transition-width bg-sky-700"></div>
        </div>
        <div class="inline-flex flex-col items-center pointer-events-none">
          <app-icon
            class="inline-flex pointer-events-none"
            [icon]="fileName === '' ? 'image-upload-icon.svg' : 'image-added-icon.svg'"
            color="slate-400"
            width="w-6"
          ></app-icon>
          <app-description
            [type]="descriptionType.SMALL"
            [text]="fileName === '' ? 'user_page.event.form_popup.add_image.text' : getShorterFileName(fileName)"
            customClasses="mt-2 pointer-events-none"
          ></app-description>

          <input
            accept=".png, .jpeg, .jpg"
            type="file"
            (change)="onFileInputChange($event.target)"
            #fileInput
            class="hidden"
          />

          <app-button
            class="mt-3 rounded-full"
            (click)="fileInput.click()"
            [class]="isFileHovered ? 'pointer-events-none' : 'pointer-events-auto'"
            [buttonInfo]="{
              type: ButtonType.ROYAL_BLUE_OUTLINE_SMALL,
              text:
                fileName === ''
                  ? 'user_page.event.form_popup.add_image.button_add'
                  : 'user_page.event.form_popup.add_image.button_add_another'
            }"
          ></app-button>
        </div>
      </div>

      <app-toggle-switch
        (click)="toggle(element.id)"
        [isOn]="!!formCredentials[element.id]"
        *ngIf="element.type === FormInputType.SWITCH"
      ></app-toggle-switch>

      <select
        (change)="selectValue(val.value, element.id)"
        #val
        class="bg-white text-sm block p-2.5 shadow-input rounded border border-neutral-300 w-full mt-1 capitalize"
        *ngIf="element.type === FormInputType.OPTIONS"
      >
        <!-- TODO: Need to remade this so it can  -->
        <option *ngFor="let role of Roles" [value]="role" [selected]="role == formCredentials[element.id]">
          {{ 'admin_page.users_table.edit_user.form_popup.select_options.role.' + role | translate }}
        </option>
      </select>

      <div *ngIf="element.hasRouterLink" class="absolute right-0 -top-0">
        <a class="text-sm font-medium text-sky-700" [routerLink]="element.routerLink">
          {{ element.routerLinkText | translate }}</a
        >
      </div>
      <ul
        class="absolute flex flex-col items-end w-full pt-1 top-full"
        *ngIf="formInfo.containsErrorMsg && hasError(element.inputId)"
      >
        <li class="ml-2 text-xs font-normal tracking-tighter" [ngClass]="getErrorColor()">
          {{ errMsg(element.inputId) | translate }}
        </li>
      </ul>
    </div>
  </div>

  <div class="mt-1" *ngIf="formInfo.type === FormType.LOGIN || formInfo.type === FormType.REGISTRATION">
    <div>
      <re-captcha
        #captchaRef="reCaptcha"
        (resolved)="resolvedRecaptcha($event)"
        (errored)="onError($event)"
        errorMode="handled"
        size="invisible"
      ></re-captcha>

      <p class="mt-4 text-sm font-normal text-zinc-600" [innerHTML]="'global.text.google_recaptcha' | translate"></p>
    </div>
  </div>

  <div [class]="getBtnContainerClasses()">
    <ng-content select="[second-btn]"></ng-content>

    <app-button [buttonInfo]="getSubmitButtonInfo()"></app-button>
  </div>
</form>
